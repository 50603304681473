<template>    
    <div  class="header-tabs">
      <b-nav tabs>
        <b-nav-item    disabled><b>Quiz App</b></b-nav-item>
        <b-nav-item    disabled><b>Counter [ correct / total ] : {{ numCorrect }} / {{ numTotal }} </b></b-nav-item>
      </b-nav>
    </div>
</template>



<script>

export default{
  props:[
    "numCorrect",
    "numTotal"
  ]
}


</script>