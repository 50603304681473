<template>
  <div id="app">    

    <Header
      :numCorrect="numCorrect"
      :numTotal="numTotal"
      
    ></Header>

    <div   class="my_container">
          <QuestionBox    
            v-if="questions.length"
            :currentQuestion="questions[index]"
            :next="next"
            :increment="increment"
          ></QuestionBox>
    </div>

  </div>
</template>

<script>
import Header from './components/Header.vue'
import QuestionBox from './components/QuestionBox.vue'


export default {
  name: 'App',
  components: {
    Header,
    QuestionBox
  },

  data(){   
    return {
      questions: [],  
      index: 0,       
      numCorrect: 0,  
      numTotal: 0     
    }
  },

  methods: {
    next(){
      this.index++
    },
    increment(isCorrect){
      if(isCorrect){
        this.numCorrect++
      }

      this.numTotal++
    }
  },
  
  mounted: function(){
    fetch('https://opentdb.com/api.php?amount=10&category=27', {  
      method: "get"   
    }).then((response)=>{ 
      return response.json()  
    }).then((jsonData) => {
      this.questions = jsonData.results
    })
  }
}
</script>



<style>
body{
  background-color: #313131;
	color: rgb(200,200,200);
	padding: 20px;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin-top: 60px;

  padding: 10px;
	border-radius: 10px;
	border: solid orange 2px;
}

.my_container{
  width: 80%;
  height: auto;
  display: block;
  margin: auto auto;
}

.header-tabs b{
  color: orange;
}


</style>
