<template>
    <div  class="question-box-container">
      <br>
      <b-jumbotron>
        
        <template slot="lead">
          {{ currentQuestion.question }}
        </template>

        <hr class="my-4">

        <b-list-group>
          <b-list-group-item  
          v-for="(answer , index) in answers"  
          :key="index"
          @click="selectAnswer(index)"
          :class="answerClass(index)"
          >
           {{ answer }}
          </b-list-group-item>
        </b-list-group>

        <b-button 
          variant="primary"
          @click="submitAnswer"
          :disabled="selectedIndex === null || answered"
        >
          Confirm
        </b-button>&nbsp;

        <b-button @click="next" variant="success" href="#">Next</b-button>
        
      </b-jumbotron>
    </div>
</template>




<script>

import _ from "lodash"    // importovana funkcionalnost koja randomizuje redosled pitanja pri svakom ucitavanju

export default{

  props: {
    currentQuestion: Object,   // Deklaracija podatka za vezu sa spoljnim svetom, izvan ove komponente. Da bi se povezala i ispisala vrednost trenutnog pitanja, moraju se povezati App.vue [parent component] i QuestionBox.vue [child component], preko ovog dela u "props" . "currentQuestion" je objekat a tekuce pitanje je u polju - question .
    next: Function,
    increment: Function
  },
  data: function(){
    return{
      selectedIndex: null,
      correctIndex: null,
      shuffledAnswers: [],
      answered: false
    }
  },

  computed: {
    answers(){    // spajanje tacnog i netacnih odgovora u setu jednog pitanja
       let answers = [...this.currentQuestion.incorrect_answers]    // making a copy of array, instead of reference to it
       answers.push(this.currentQuestion.correct_answer)
       return answers
    }
  },

  watch:{
    currentQuestion: {    // this is OBJECT
      immediate: true,    // first and every next time, when new question data comes, run handler function
      handler(){
        this.selectedIndex = null
        this.answered = false
        this.shuffleAnswers()
      }
    }
    
  },

  methods: {
    selectAnswer(index){
      this.selectedIndex = index
      // console.log(index)
      // alert(index)
    },
    submitAnswer(){
      let isCorrect = false
      if(this.selectedIndex === this.correctIndex){
        isCorrect = true
      }
      this.answered = true    // da li je odgovoreno na pitanje, selektovan odgovor. Posto ako nije, dugme za potvrdu ce biti disable-ovano.
      this.increment(isCorrect)
    },

    shuffleAnswers(){
      let answers = [...this.currentQuestion.incorrect_answers, this.currentQuestion.correct_answer]
      this.shuffledAnswers = _.shuffle(answers)    // lodash.shuffle
      this.correctIndex = this.shuffledAnswers.indexOf(this.currentQuestion.correct_answer)
    },

    answerClass(index){   // svaki put kada objekat promeni INDEX, selektovanjem odgovora, automatski "ispod haube", verovatno VUE, pozove ovu metodu i primeni properti nove klase na selektovani element .
      let answerClass = ""
      if( !this.answered && this.selectedIndex === index ){   // ako nisam potvrdio odgovor, a selektovao sam novi odgovor
        answerClass = "selected"
      }else if( this.answered && this.correctIndex === index ){   // ako sam i potvrdio odogovor, i odgovor je tacan
        answerClass = "correct"
      }else if( this.answered && this.selectedIndex === index && this.correctIndex !== index ){ // ako sam i potvrdio odgovor, i odgovor je netacan, ali je i selektovan
        answerClass = "incorrect"
      }

      return answerClass

    }
  },
  //  mounted(){
  //    this.shuffleAnswers()
  //  }
 

}

</script>



<style   scooped>
  .list-group{
    margin-bottom: 20px;
  }

  .list-group-item:hover{
    background: gray;
    cursor:pointer;
  }

  .btn{
    margin: 0 10px;
  }

  .selected{
    background: orange;
  }
  .correct{
    background: green;
  }
  .incorrect{
    background: red;
  }
</style>